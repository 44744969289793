import { mpLogin } from "@/api/wxuser";
import { Toast } from "vant";
export default {
  name: "auth",
  created() {
    // 如果连接中有微信返回的 code，则用此 code 调用后端接口，向微信服务器请求用户信息
    if (this.$route.query.code) {
      try {
        let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
        debugger;
        mpLogin({
          jsCode: this.$route.query.code
        }).then(response => {
          if (response.data.code === 0) {
            //将一些信息存储到本地
            // const token = res.headers['accesstoken']
            // localStorage.setItem('token', token)
            // localStorage.setItem("wxUserInfo", JSON.stringify(res.data.root));
            localStorage.setItem("open-id", response.data.data.openId);
            localStorage.setItem("third-session", response.data.data.sessionKey);
          }
          this.$router.replace(redirectUrl); //跳转到业务页面
        }).catch(e => {
          console.log(e);
          Toast({
            message: '获取openid失败'
          });
        });

        // let res = await axios.get('https://api.weixin.qq.com/sns/oauth2/access_token',{
        //   code: this.$route.query.code,
        //   appid: 'wx95950b26c2eb88ac',
        //   secret: 'c95627f5580cdd80102cfda846fdc5d8',
        //   grant_type:'authorization_code'
        // }).then(function(response) {
        //   if (response.data) {
        //     //将一些信息存储到本地
        //     // const token = res.headers['accesstoken']
        //     // localStorage.setItem('token', token)
        //     // localStorage.setItem("wxUserInfo", JSON.stringify(res.data.root));
        //     localStorage.setItem("openId", response.data.openid);
        //   }
        //   // localStorage.setItem("openId",'fsd')
        //   this.$router.replace(redirectUrl);//跳转到业务页面
        // }).catch(function(){
        //   Toast({//提示引用的是mint-UI里toast
        //     message: '获取openid失败'
        //   });
        // });
      } catch (error) {
        console.log(error);
      }
    } else {
      // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
      this.$router.replace("/home");
    }
  },
  methods: {
    getWxUserInfo() {}
  }
};